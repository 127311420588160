import { h } from '@stencil/core';

function renderHeader() {
  return (
    <ion-header>
        <ion-row class="header-row">
          <ion-col class="logo-col">
            <img src="../../assets/images/VF_logo.jpeg" alt="VF Logo"></img>
            </ion-col>
          <ion-col class="header-text-col">Seamless Charging Pilot</ion-col>
        </ion-row>
    </ion-header>
  );
}
export default renderHeader;
